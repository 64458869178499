// Normalize Styles
@import (inline) 'node_modules/normalize.css/normalize.css';
// @import (inline) 'node_modules/animate.css/animate.css';
@import (inline) 'node_modules/swiper/dist/css/swiper.css';
@import url('https://fonts.googleapis.com/css?family=Playfair+Display:400,700');
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500');


// Import Modules
@import './_home';
@import '../_modules/footer/footer';
@import '../_modules/header/header';
@import './_kavekapszula';
@import './_exkluziv';
@import './_kompatibilitas';
@import './_termekek';
@import './_kaves-desszertek';
@import './_ajandek-kapszula';
// @import './_omniarol';
// @import './_minosegi-garancia';
// @import './_omnia-classic';
// @import './_omnia-gold';
// @import './_omnia-espresso';
// @import './_omnia-barista';
// @import './_omnia-silk'; 
// @import './_omnia-evening';
// @import './_error';

// Box sizing partial styles:
// Apply a natural box layout model to all elements
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  // font-family: 'Playfair Display', serif;
  font-family: 'Montserrat', sans-serif;
  width: 100%;
  height: 100%;
  background-color: #111111;
}

// FIX MENÜ MIATTI ELTARTÁS AZ OLDAL TETEJÉTŐL
// header + section {
//   margin-top: 143px;
// }

p, h1, h2, h3, h4, h5 {
  text-align: justify;
}

a {
  text-decoration: none;
  color: inherit;
}

/* Shutter Out Horizontal */
.hvr-shutter-out-horizontal {
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  position: relative;
  background: transparent;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hvr-shutter-out-horizontal:before {
  content: "";
  position: absolute;
  border-radius: 8px;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(90deg, #81652c, #edcd79, #81652c);
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-shutter-out-horizontal:hover span, .hvr-shutter-out-horizontal:focus span, .hvr-shutter-out-horizontal:active span {
  background-image: none;
  color: black !important;
}
.hvr-shutter-out-horizontal:hover:before, .hvr-shutter-out-horizontal:focus:before, .hvr-shutter-out-horizontal:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

// .hvr-sweep-to-right {
//   display: inline-block;
//   vertical-align: middle;
//   transform: perspective(1px) translateZ(0);
//   box-shadow: 0 0 1px rgba(0, 0, 0, 0);
//   position: relative;
//   transition-property: color;
//   transition-duration: 0.3s;
// }
// .hvr-sweep-to-right:before {
//   content: "";
//   position: absolute;
//   z-index: -1;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background: #c59000;
//   transform: scaleX(0);
//   transform-origin: 0 50%;
//   transition-property: transform;
//   transition-duration: 0.3s;
//   transition-timing-function: ease-out;
// }
// .hvr-sweep-to-right:hover,
// .hvr-sweep-to-right:focus,
// .hvr-sweep-to-right:active {
//   color: white;
// }
// .hvr-sweep-to-right:hover svg path,
// .hvr-sweep-to-right:focus svg path,
// .hvr-sweep-to-right:active svg path {
//   fill: white;
// }
// .hvr-sweep-to-right:hover:before, .hvr-sweep-to-right:focus:before, .hvr-sweep-to-right:active:before {
//   transform: scaleX(1);
// }

#cookie-bar { background:#bc9d42; height:auto; line-height:24px; color:#eeeeee; text-align:center !important; padding:10px 30px 30px 30px; }
#cookie-bar.fixed {position:fixed; top:0; left:0; width:100%; z-index: 1200}
#cookie-bar.fixed.bottom {bottom:0; top:auto;}
#cookie-bar p {margin:0; padding:0; text-align:center !important;}
#cookie-bar a {color:#ffffff; display:inline-block; border-radius:3px; text-decoration:none; padding:0 6px; margin-left:8px;}
#cookie-bar .cb-enable, #cookie-bar .cb-policy {background:#bc9d42; cursor: pointer; border: 1px solid #ffffff;}
#cookie-bar .cb-enable:hover, #cookie-bar .cb-policy:hover {background:#ffffff; color: #bc9d42;}
// #cookie-bar .cb-disable {background:#990000; cursor: pointer;}
// #cookie-bar .cb-disable:hover {background:#bb0000;}
// #cookie-bar .cb-policy {background:#0033bb; cursor: pointer;}
// #cookie-bar .cb-policy:hover {background:#0055dd;}