section.products_hero {
    height: 550px;
    background: url('../images/termekek/hero_bg.jpg');
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    display: flex;
    justify-content: center;
}

section.products_hero_new {
    background-image: url('../images/section1_bckg.jpg');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
    display: flex;
    justify-content: center;
    position: relative;
    min-height: 700px;
}
section.products_hero_new .cup_capsula {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 50%;
}

section.products_hero_new .cup_capsula img {
    width: 100%;
}

section.products_content {
    max-width: 1120px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -350px;
    margin-bottom: 70px;
}

section.products_content.new {
    margin-top: 0;
}

section.products_content.legal_text {
    color: white;
    font-weight: 300;
}

section.products_content div.product_container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 20px 0;
}

section.products_content div.product_container.wrap_reverse {
    flex-flow: row wrap-reverse;
}

div.product_container div.product_description {
    max-width: 560px;
    font-family: 'Playfair Display', serif;
    color: #e9c977;
}

div.product_container div.product_description span,
div.product_description h5 {
    color: white;
}

div.product_description h3,
div.product_description h5 {
    margin: 10px 0;
}

div.product_description h3 {
    font-size: 33px;
    font-weight: 400;
}

div.product_description h3.characteristic_hl {
    font-size: 24px;
}

div.product_description h5 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 18px;
}

div.product_description div.product_attributes {
    width: 100%;
    height: 56px;
    display: flex;
    flex-flow: row wrap;
    margin-top: 10px;
}

div.product_description div.product_attributes div {
    width: calc(100% / 3);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1f1f1f;
}

_:-ms-lang(x), div.product_description div.product_attributes div {
    width: 33.33%;
}

div.product_description div.product_attributes div:nth-child(2) {
    border-left: 1px solid #111111;
    border-right: 1px solid #111111;
}

div.product_description div.product_attributes h5 {
    font-size: 12px;
    color: #e9c977;
    text-align: center;
}

div.product_description div.product_attributes h5 span {
    font-family: 'Playfair Display', serif;
    font-size: 14px;
}

div.product_description p {
    color: white;
    font-size: 14px;
    line-height: 28px;
}

div.hr_dark_line {
    background-color: #2b2b2b;
    width: 100%;
    height: 3px;
}

div.cup_sizes_container {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
}

div.cup_sizes_container div.cup_sizes {
    width: calc(100% / 2);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 15px;
}

div.cup_sizes_container div.cup_sizes p {
    margin: 5px 0;
    text-align: center;
}

div.cup_sizes div.cup_size {
    text-align: center;
}

// *************
// MEDIA QUERY
// *************

@media screen and (max-width: 960px) {
    section.products_content div.product_container {
        justify-content: center;
        padding: 0 15px;
    }
}

@media screen and (max-width: 450px) {
    section.products_content div.product_container div.product_packshot,
    section.products_content div.product_container div.product_packshot img {
        width: 100%;
    }

    div.product_description div.product_attributes {
        flex-flow: column;
        height: 168px;
    }

    div.product_description div.product_attributes div {
        width: 100%;
    }

    div.product_description div.product_attributes div:nth-child(2) {
        border-left: none;
        border-right: none;
        border-top: 1px solid #111111;
        border-bottom: 1px solid #111111;
    }

    div.cup_sizes_container {
        justify-content: center;
    }

    div.cup_sizes_container div.cup_sizes {
        width: 80%;
    }

    div.product_description h3 {
        font-size: 28px;
    }

    div.product_description h5 {
        font-size: 15px;
    }

    div.product_description h3.characteristic_hl {
        font-size: 20px;
    }
}