h1.main_heading,
h2.sub_hl {
    display: inline-block;
    background-image: linear-gradient(90deg, #81652c, #edcd79, #81652c);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    font-weight: 400;
}

_:-ms-lang(x), .ie10upMainHeading {
    color: #edcd79 !important;
    background-image: none !important;
    margin: 80px 0 90px 0 !important;
}

_:-ms-lang(x), .ie10upParagraph {
    color: #edcd79 !important;
    background-image: none !important;
}

_:-ms-lang(x), .ie10upButton span {
    color: #edcd79 !important;
    background-image: none !important;
}

h1.main_heading {
    font-size: 42px;
    margin: 80px auto 90px;
}

h2.sub_hl {
    font-size: 25px;
}

h1.main_heading.slide {
    font-size: 55px;
}

section.carousel {
    width: 100%; 
    position: relative;
}

div.swiper-container.top_slider {
    width: 100%;
    height: 770px;
    position: relative;
}

div.nav_arrow {
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    padding: 0 30px;
    z-index: 100;
}

div.nav_arrow img {
    transition: 0.2s ease-out;
    cursor: pointer;
}

div.nav_arrow img:hover {
    transform: scale(1.1);
}

div.nav_arrow.left {
    top: 0;
    left: 0;
}

div.nav_arrow.right {
    top: 0;
    right: 0;
}

div.swiper-container.top_slider div.swiper-wrapper {
    width: 100%;
    height: 100%;
}

div.swiper-container.top_slider div.swiper-slide {
    width: 100%;
    height: 100%;
}

div.swiper-slide.slide_2 div.carousel_content {
    margin: 0 auto;
    max-width: 1570px;
    width: 1570px;
}

div.swiper-slide.slide_2 div.carousel_content div.capsule_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    position: relative;
}

div.swiper-slide.slide_2 div.carousel_content div.capsule_wrapper:nth-child(3) {
    top: -25px;
}

div.swiper-slide.slide_2 div.carousel_content div.capsule_wrapper div.capsule img {
    width: 100%;
}

div.swiper-slide.slide_2 div.carousel_content div.capsule_wrapper div.capsule.capsule_text {
    text-align: center;
}

div.swiper-slide.slide_2 div.carousel_content div.capsule_wrapper div.capsule.capsule_text img {
    width: 70%;
}

// div.swiper-slide.slide_2 div.carousel_content div.capsule_wrapper div.capsule img:last-child {
//     width: 60%;
// }

div.swiper-slide.slide_2 div.carousel_content h1.main_heading {
    // display: block;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin: 0px auto 20px;
}

div.swiper-slide.slide_2 div.carousel_content h1.main_heading span {
    font-size: 35px;
    font-style: italic;
}

div.swiper-slide.slide_3 div.carousel_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 710px;
}

div.swiper-slide.slide_3 div.carousel_content h4 {
    font-size: 22px;
    display: inline-block;
    background-image: linear-gradient(90deg, #81652c, #edcd79, #81652c);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    font-weight: 400;
    margin: 0;
    text-align: center;
    line-height: 32px;
    width: 100%;
}

div.swiper-container.top_slider div.swiper-slide.slide_3 .main_heading {
    width: 100%;
}

div.swiper-slide.slide_3 div.carousel_content img {
    width: 16vw;
}

div.swiper-slide.slide_3 img.slider_packshot {
    width: 30vw;
}

div.carousel_content h1,
div.carousel_content p {
    text-align: center;
}

div.swiper-container.top_slider div.swiper-slide.slide_1 {
    background: url('../images/slide_1_bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

div.swiper-container.top_slider div.swiper-slide.slide_2 {
    background: url('../images/slide_2_bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
}

div.swiper-container.top_slider div.swiper-slide.slide_3 {
    display: flex;
    align-items: center;
    justify-content: center;
}

// div.swiper-slide.slide_2 .main_heading {
//     margin: 0;
//     margin-bottom: 20px;
// }

div.swiper-slide.slide_2 p {
    font-family: 'Playfair Display', serif;
    font-weight: 400;
    font-style: italic;
    font-size: 24px;
    line-height: 30px;
    // display: inline-block;
    background-image: linear-gradient(90deg, #81652c, #edcd79, #81652c);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
}

// div.swiper-slide.slide_3 {
//     background: url('../images/slide_1_bg.jpg');
//     background-repeat: no-repeat;
//     background-size: cover;
//     background-position: center;
// }

div.swiper-container.top_slider div.swiper-slide.slide_1 div.button_big {
    margin: 0 auto;
}

div.swiper-container.top_slider div.swiper-slide.slide_1 {
    display: flex;
    // flex-flow: row wrap;
    justify-content: center;
    width: 600px;
    padding-top: 40px;
}

.swiper-button-next {
    background: url('../images/right_arrow.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.swiper-button-prev {
    background: url('../images/left_arrow.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

div.swiper-pagination {
    position: absolute;
    display: flex;
    flex-flow: row;
    justify-content: center;
    bottom: 0px;
    padding: 20px 0;
}

div.swiper-pagination span.dot {
    position: relative;
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: transparent;
    border: 2px solid #be9d3a;
    margin: 0 5px;
    cursor: pointer;
}

div.swiper-pagination span.active_slide::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-55%, -60%);
    background: #be9d3a;
    width: 5px;
    height: 5px;
    border-radius: 50%;
}

div.products_container div.swiper-pagination span.active_slide::before {
    transform: translate(-60%, -60%);
}

div.legal_stripe h3 {
    background: linear-gradient(90deg,#81652c 20%, #edcd79 30%,#81652c 50%,#edcd79 70%,#81652c 80%);
    color: #111111;
    font-size: 16px;
    letter-spacing: 3px;
    margin: 0;
    padding: 20px 0;
    text-align: center;
}

div.legal_stripe p {
    color: white;
    font-size: 12px;
    font-weight: 300;
    text-align: center;
}

section.main_video {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

div.video_container {
    width: 100%;
    margin: 40px 0 60px 0;
}

div.video_container video {
    width: 100% !important;
    height: auto !important;
}

div.video_container img {
    width: 100%;
}

// div.video_container {
// 	position: relative;
// 	padding-bottom: 56.25%; /* 16:9 */
// 	padding-top: 25px;
//     height: 0;
//     margin: 40px 0 60px 0;
// }

// div.video_container iframe {
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// 	width: 100%;
// 	height: 100%;
// }

section.products {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

section.products div.products_container {
    width: 100%;
    // display: flex;
    // flex-flow: row wrap;
    // justify-content: space-between;
    // align-items: center;
    padding-bottom: 80px;
}

section.products div.products_container div.swiper-wrapper {
    align-items: flex-end;
}

section.products div.products_container div.product {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

div.products_container div.product h3,
div.products_container div.product h4 {
    font-family: 'Playfair Display', serif;
    font-weight: 300;
    letter-spacing: 2px;
    text-align: center;
    margin: 0;
}

div.products_container div.product h3 {
    color: #e9c977;
    font-size: 20px;
}

div.products_container div.product h4 {
    color: white;
    font-size: 14px;
    margin-top: 15px;
}

div.products_container div.product h3 span {
    color: white;
}

section.excellence {
    background-color: #1e1e1e;
    text-align: center;
    padding: 50px 0 60px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

section.excellence div.attributes_wrapper {
    width: 970px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-end;
}

section.excellence div.attributes_wrapper div.attribute {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

div.attributes_wrapper div.attribute h3,
div.attributes_wrapper div.attribute p {
    text-align: center;
    font-family: 'Playfair Display', serif;
    font-weight: 300;
}

div.attributes_wrapper div.attribute h3 {
    color: #e9c977;
    font-size: 24px;
    letter-spacing: 2px;
}

div.attributes_wrapper div.attribute p {
    color: white;
    font-size: 14px;
    line-height: 34px;
}

section.bottom_logo {
    display: flex;
    justify-content: center;
    padding: 90px 0;
}

div.button_big,
div.button_small {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(90deg, #81652c, #edcd79, #81652c);
    border-radius: 10px;
    cursor: pointer;
    margin: 0 auto;
}

div.button_big div:first-child,
div.button_small div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #111111;
    border: 2px solid #111111;
    border-radius: 8px;
}

div.button_big span,
div.button_small span {
    position: relative;
    display: inline-block;
    background-image: linear-gradient(90deg, #81652c, #edcd79, #81652c);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    z-index: 1;
    font-size: 14px;
    letter-spacing: 4px;
    font-weight: 400;
}

div.button_big {
    width: 400px;
    height: 42px;
}

div.button_small {
    width: 250px;
    height: 36px;
    margin-top: 30px;
}

div.button_small.other_bg div:first-child {
    background: #1e1e1e;
    border: 2px solid #1e1e1e;
}

div.button_big div:first-child {
    width: 396px;
    height: 38px;
}

div.button_small div:first-child {
    width: 246px;
    height: 32px;
}

// **************
// MEDIA QUERY
// **************

@media screen and (max-width: 1450px) {
    div.swiper-container.top_slider {
        height: 590px;
    }

    div.swiper-container.top_slider div.swiper-slide.slide_2 {
        align-items: unset;
    }

    div.swiper-slide.slide_3 div.carousel_content h1.main_heading {
        font-size: 34px;
        margin: 30px auto 50px;
    }
}

@media screen and (max-width: 980px) {
    h1.main_heading {
        font-size: 34px !important;
        margin: 30px auto 60px;
        padding: 0 20px;
        text-align: center;
        line-height: 48px !important;
    }

    div.swiper-slide.slide_2 div.carousel_content h1.main_heading span {
        font-size: 25px;
    }

    h1.main_heading.slide {
        font-size: 45px;
    }

    div.swiper-container.top_slider {
        height: 86vh;
    }

    div.swiper-slide.slide_2 div.carousel_content {
        transform: translateX(0);
        background: rgba(0, 0, 0, 0.4);
        padding: 20px;
        max-width: 470px;
        border-radius: 10px;
    }

    div.swiper-slide.slide_2 div.carousel_content div.capsule_wrapper:nth-child(3) {
        top: 0px;
    }

    section.excellence div.attributes_wrapper {
        width: 100%;
        justify-content: center;
    }

    section.excellence div.attributes_wrapper div.attribute {
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 810px) {
    div.swiper-slide.slide_1 div.carousel_content_mobile {
        display: flex;
        flex-direction: column;
    }

    div.swiper-slide.slide_1 div.carousel_content {
        display: none;
    }

    // div.swiper-slide.slide_1 div.button_big {
    //     display: none;
    // }

    div.swiper-slide.slide_1 div.carousel_content_mobile div:last-child img:first-child {
        height: 455px;
    }

    div.swiper-slide.slide_3 div:first-child,
    div.swiper-slide.slide_3 div:last-child {
        display: none;
    }

    div.swiper-slide.slide_3 div.carousel_content img {
        width: 30vw;
    }

    div.swiper-slide.slide_3 div.carousel_content h1.main_heading {
        font-size: 23px;
    }

    div.swiper-slide.slide_3 div.carousel_content h4 {
        font-size: 14px;
        line-height: 22px;
        padding: 0 10px;
    }
}

@media screen and (max-width: 600px) {
    // div.swiper-container {
    //     height: 390px;
    // }

    h1.main_heading.slide {
        font-size: 35px;
    }

    div.legal_stripe h3 {
        font-size: 14px;
        padding: 20px 20px;
    }

    div.legal_stripe p {
        padding: 0 20px;
    }

    div.swiper-slide.slide_2 {
        background: url('../images/slide_2_bg_m.jpg') !important;
        background-repeat: no-repeat !important;
        background-size: cover;
        background-position: center;
    }

    // div.swiper-pagination {
    //     display: none;
    // }

    div.swiper-slide.slide_1 div.carousel_content_mobile div:last-child img:first-child {
        width: 31vw;
        height: auto;
    }
    
    div.swiper-slide.slide_1 div.carousel_content_mobile div:last-child img:last-child {
        width: 24vw;
    }

    div.swiper-slide.slide_2 div.carousel_content h1 {
        font-size: 8vw;
    }

    div.swiper-slide.slide_2 div.carousel_content h4 {
        font-size: 6vw;
    }

    // div.button_big {
    //     width: 170px;
    //     height: 40px;
    //     padding: 0 25px 0 25px;
    //     margin: 0 auto;
    // }

    // div.button_big span {
    //     font-size: 11px;
    // }
}

@media screen and (max-width: 410px) {
    h1.main_heading {
        font-size: 26px !important;
        margin: 15px auto 30px;
        padding: 0 20px;
        text-align: center;
        line-height: 35px !important;
    }

    h1.main_heading.slide {
        font-size: 28px;
    }

    div.button_big {
        width: 280px;
        height: 42px;
    }

    div.button_big div:first-child {
        width: 276px;
        height: 38px;
    }

    div.button_big span {
        font-size: 12px;
    }

    section.bottom_logo {
        padding: 30px 0;
        display: block;
    }

    section.bottom_logo img {
        width: 80%;
        margin: 0 auto;
        display: block;
    }

    div.swiper-slide.slide_1 div.carousel_content_mobile div:last-child img:first-child {
        width: 43vw;
    }
    
    div.swiper-slide.slide_1 div.carousel_content_mobile div:last-child img:last-child {
        width: 34vw;
    }
}