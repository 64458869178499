section.exkluziv_hero {
    height: 520px;
    background-image: url('../images/exclusive/hero_bg.jpg');
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    display: flex;
    justify-content: center;
    position: relative;
}
section.gift_capsules {
    background-image: url('../images/section3_bckg.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    margin-bottom: 70px;
}

section.gift_capsules div.gift_capsules_wrapper {
    max-width: 1120px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 400px;
}

section.gift_capsules div.gift_capsules_wrapper h3 {
    font-family: 'Playfair Display', serif;
    font-weight: 300;
    letter-spacing: 2px;
    margin: 0;
    color: #ffffff;
    font-size: 27px;
    font-style: italic;
    width: 340px;
    text-align: center;
    background-image: -webkit-gradient(linear, left top, right top, from(#81652c), color-stop(#edcd79), to(#81652c));
    background-image: -webkit-linear-gradient(left, #81652c, #edcd79, #81652c);
    background-image: linear-gradient(90deg, #81652c, #edcd79, #81652c);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
}

section.gift_capsules div.gift_capsules_wrapper h3 span {
    display: block;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    font-style: normal;
}

section.gift_capsules div.gift_capsules_wrapper a {
    width: 250px;
}

section.exkluziv_hero .main_heading {
    position: absolute;
    top: 50%;
    transform: translateY(-75%);
    left: 27%;
    text-align: center;
    line-height: 65px;
}
div.girl_row img {
    width: 100%;
    display: block;
}

section.products_hero_new div.showcase_wrapper h1,
div.all_product h3 {
    font-family: 'Playfair Display', serif;
    font-weight: 300;
    letter-spacing: 2px;
    margin: 0;
    background-image: -webkit-gradient(linear, left top, right top, from(#81652c), color-stop(#edcd79), to(#81652c));
    background-image: -webkit-linear-gradient(left, #81652c, #edcd79, #81652c);
    background-image: linear-gradient(90deg, #81652c, #edcd79, #81652c);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    font-size: 27px;
    font-style: italic;
}

_:-ms-lang(x), section.products_hero_new div.showcase_wrapper h1,
_:-ms-lang(x), div.all_product h3 {
    font-family: serif;
}

section.products_hero_new div.showcase_wrapper h1 {
    font-size: 51px;
    margin-top: 50px;
}

section.products_hero_new div.showcase_wrapper h2 {
    font-family: 'Playfair Display', serif;
    font-weight: 300;
    letter-spacing: 2px;
    color: #ffffff;
    font-size: 26px;
    /*font-style: italic;*/
    text-transform: uppercase;
    margin-top: 50px;
}

section.products_hero_new div.showcase_wrapper a div {
    display: inline-flex;
}

div.logo_block {
    display: flex;
    justify-content: left;
    position: relative;
    width: 460px;
    margin-top: 50px;
}

div.logo_block img {
    width: 109px;
    height: 69px;
    margin-left: -5px;
}
div.logo_block p {
    display: block;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    font-style: normal;
    background-image: -webkit-gradient(linear, left top, right top, from(#81652c), color-stop(#edcd79), to(#81652c));
    background-image: -webkit-linear-gradient(left, #81652c, #edcd79, #81652c);
    background-image: linear-gradient(90deg, #81652c, #edcd79, #81652c);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    font-size: 16px;
    padding-bottom: 0;
    margin-bottom: 0;
    margin-left: 20px;
    margin-top: 30px;
}

section.products_hero_new div.showcase_wrapper h1 span,
div.all_product h3 span {
    display: block;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    font-style: normal;
}

_:-ms-lang(x), section.products_hero_new div.showcase_wrapper h1 span,
_:-ms-lang(x), div.all_product h3 span {
    font-family: arial;
}

div.all_product h3.left_side {
    text-align: left;
}

div.all_product h3.left_side span:after {
    width: 35%;
    height: 1px;
    background: linear-gradient(90deg,#81652c 20%, #edcd79 30%,#81652c 50%,#edcd79 70%,#81652c 80%);
    content: '';
    display: inline-block;
    vertical-align: top;
    margin-left: 730px;
    margin-top: -34px;
}

div.all_product h3.right_side {
    text-align: right;
}

div.all_product h3.right_side span:before {
    width: 54%;
    height: 1px;
    background: linear-gradient(90deg,#81652c 20%, #edcd79 30%,#81652c 50%,#edcd79 70%,#81652c 80%);
    content: '';
    display: inline-block;
    vertical-align: bottom;
    margin-right: 50px;
    margin-bottom: 6px;
}

div.all_product img {
    width: 100%;
    display: block;
    text-align: center;
}

section.exkluziv_articles {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;
}

div.legal_stripe_new {
    background: linear-gradient(90deg,#81652c 20%, #edcd79 30%,#81652c 50%,#edcd79 70%,#81652c 80%);
    margin: 0;
    padding: 20px 0;
}

div.legal_stripe_new h3 {
    color: #111111;
    font-size: 16px;
    letter-spacing: 3px;
    text-align: center;
    margin: 0;
}
div.legal_stripe_new p {
    color: #111111;
    font-size: 12px;
    font-weight: 300;
    text-align: center;
    margin: 0;
}

div.hr_gradient_line {
    width: 100%;
    height: 3px;
    background: linear-gradient(90deg, #81652c, #edcd79, #81652c);
}

div.hr_deliziosa_line {
    width: 100%;
    height: 18px;
    background: #f38623;
}

div.hr_supremo_line {
    width: 100%;
    height: 18px;
    background: #493548;
}

div.hr_satinato_line {
    width: 100%;
    height: 18px;
    background: #59c29b;
}

div.hr_lungoelegante_line {
    width: 100%;
    height: 18px;
    background: #a3d958;
}

div.hr_deliziosa_line + div.product_attributes,
div.hr_lungoelegante_line + div.product_attributes,
div.hr_supremo_line + div.product_attributes,
div.hr_satinato_line + div.product_attributes {
    margin-top: 0 !important;
}

section.exkluziv_articles div.articles_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 1100px;
    height: 740px;
}

section.exkluziv_articles div.articles_container div.article_wrapper {
    width: 290px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

div.articles_container div.article_wrapper div.art_img {
    width: 290px;
    height: 290px;
    border-radius: 50%;
}

div.articles_container div.article_wrapper:first-child div.art_img {
    background: url('../images/exclusive/article_img_1.jpg');
    background-position: center;
}

div.articles_container div.article_wrapper:nth-child(2) div.art_img {
    background: url('../images/exclusive/article_img_2.jpg');
    background-position: center;
}

div.articles_container div.article_wrapper:last-child div.art_img {
    background: url('../images/exclusive/article_img_3.jpg');
    background-position: center;
}

div.articles_container div.article_wrapper h3,
div.articles_container div.article_wrapper p {
    font-family: 'Playfair Display', serif;
    font-weight: 400;
    max-width: 290px;
}

div.articles_container div.article_wrapper h3 {
    font-size: 26px;
    letter-spacing: 3px;
    line-height: 32px;
    text-align: center;
    color: #e9c977;
}

div.articles_container div.article_wrapper p {
    font-size: 14px;
    text-align: center;
    letter-spacing: 2px;
    line-height: 26px;
    color: white;
}

// ************************
// A KÁVÉKÉSZÍTÉS MŰVÉSZETE
// ************************

section.coffeeart_hero,
section.coffeetaste_hero {
    height: 525px;
    display: flex;
    justify-content: center;
    position: relative;
}

section.coffeeart_hero {
    background: url('../images/exclusive/coffeeart_hero_bg.jpg');
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
}

section.coffeeart_hero .main_heading {
    text-align: center;
    line-height: 65px;
    position: absolute;
    top: 50%;
}

section.coffeeart_hero .main_heading {
    transform: translateY(-100%);
    left: 20%;
}

section.coffeeart_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

section.coffeeart_content div.content_wrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
    width: 90%;
    font-family: 'Playfair Display', serif;
}

div.content_wrapper div.content_img img {
    width: 100%;
}

section.coffeeart_content div.content_wrapper div.text {
    width: 390px;
}

section.coffeeart_content div.content_wrapper h2,
section.coffeeart_content div.content_wrapper h5,
section.coffeeart_content div.content_wrapper p {
    text-align: center;
    font-weight: 400;
}

section.coffeeart_content div.content_wrapper h2 {
    font-size: 28px;
    color: #e9c977;
    letter-spacing: 4px;
}

section.coffeeart_content div.content_wrapper h5,
section.coffeeart_content div.content_wrapper p {
    font-size: 13px;
    letter-spacing: 2px;
}

section.coffeeart_content div.content_wrapper h5 {
    color: #e9c977;
}

section.coffeeart_content div.content_wrapper p {
    color: white;
    line-height: 22px;
}

// ************************
// A KÓSTOLÁS MŰVÉSZETE
// ************************

section.coffeetaste_hero {
    background: url('../images/exclusive/coffeetaste_hero_bg.jpg');
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
}

section.coffeetaste_hero div:first-child {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    right: 17%;
}

section.coffeetaste_hero .main_heading {
    text-align: center;
    line-height: 65px;
    display: block;
    margin-bottom: 0;
}

section.coffeetaste_hero .sub_hl {
    text-align: center;
    display: block;
}

.coffeetaste .content_img {
    height: 100%;
}

.coffeetaste .content_img.normal_width img {
    width: initial;
}

.coffeetaste div.text.left {
    transform: translateX(80px);
}

.coffeetaste div.text.right {
    transform: translateX(-130px);
}

// ***************
// MEDIA QUERY
// ***************

@media screen and (max-width: 1620px) {
    div.content_wrapper {
        width: 100% !important;
        justify-content: center !important;
    }

    div.content_wrapper div.content_img {
        width: 60%;
    }

    section.coffeetaste_hero div:first-child {
        transform: translateY(0);
        top: 0;
    }
}

@media screen and (max-width: 1100px) {
    section.exkluziv_articles div.articles_container {
        width: 100%;
        padding: 0 20px;
    }
}

@media screen and (max-width: 950px) {
    section.exkluziv_hero {
        background-image: url('../images/exclusive/hero_bg_mobile.jpg');
        background-position: right;
    }

    section.exkluziv_articles div.articles_container {
        flex-direction: column;
        align-items: center;
        height: initial;
    }

    section.exkluziv_articles div.articles_container div.article_wrapper {
        width: 70%;
        margin-bottom: 40px;
    }

    section.exkluziv_hero .main_heading {
        line-height: 48px;
        left: 15%;
    }

    section.coffeeart_hero .main_heading {
        left: 10%;
        line-height: 44px;
    }

    section.coffeetaste_hero .sub_hl {
        font-size: 20px;
    }

    .coffeetaste .content_img {
        width: 100%;
    }

    .coffeetaste .content_img.normal_width img {
        width: 100%;
    }

    section.coffeeart_content div.content_wrapper div.text {
        transform: translateX(0);
    }
}

@media screen and (max-width: 620px) {
    section.coffeeart_hero,
    section.coffeetaste_hero {
        height: 425px;
    }

    section.coffeetaste_hero div:first-child {
        position: initial;
        margin: 0 auto;
    }

    section.coffeeart_content div.content_wrapper div.text {
        width: 100%;
        padding: 0 15px;
    }
}

@media screen and (max-width: 480px) {
    div.articles_container div.article_wrapper div.art_img {
        width: 180px;
        height: 180px;
    }

    section.exkluziv_hero .main_heading,
    section.coffeeart_hero .main_heading {
        line-height: 33px;
        left: 50%;
        transform: translateX(-50%) translateY(0);
        top: 0;
        width: 100%;
    }

    section.coffeeart_content div.content_wrapper h2,
    section.coffeeart_content div.content_wrapper p {
        padding: 0 15px;
    }

    section.coffeeart_content div.content_wrapper h2 {
        font-size: 24px;
    }
}