section.compatibility_hero {
    height: 710px;
    background: url('../images/kompatibilitas/hero_bg.jpg');
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    display: flex;
    justify-content: center;
    position: relative;
}

section.compatibility_hero .main_heading {
    text-align: center;
}

section.compatibility_content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 810px;
    margin: 0 auto 60px;
}

section.compatibility_content div.text {
    font-size: 13px;
    line-height: 32px;
}

section.compatibility_content div.text p {
    text-align: center;
    color: #a08442;
}

section.compatibility_content div.text p.playfair,
section.signup_content p.playfair {
    font-family: 'Playfair Display', serif;
    color: white;
}

// *******************
// SIKERES REGISZTRÁCIÓ
// *******************

section.success_reg_hero {
    height: 710px;
    background: url('../images/termekek/hero_bg.jpg');
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    // position: relative;
}

.center {
    text-align: center;
}

.mrg_top {
    margin-top: 70px;
}

section.success_reg_hero div img {
    width: 100%;
}

// *************
// ERROR OLDALAK
// *************

section.error_hero {
    height: 710px;
    background: url('../images/termekek/hero_bg.jpg');
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    // position: relative;
}

.mrg_bottom {
    margin-bottom: 140px;
}

.fs_54 {
    font-size: 54px !important;
}

.fs_32 {
    font-size: 32px !important;
}


// *************
// MEDIA QUERY
// *************

@media screen and (max-width: 850px) {
    section.compatibility_content {
        width: 100%;
        padding: 0 15px;
    }
}

// @media screen and (max-width: 410px) {
//     section.compatibility_hero .main_heading {
//         line-height: 40px;
//     }
// }