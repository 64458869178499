header nav.navbar {
    width: 100%;
    background: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    // padding-bottom: 10px;
    height: auto;
    max-height: 400px;
    // position: fixed;
    // z-index: 100;
    // top: 0;
    // left: 0;
    background: #252624;
}

header nav img {
    margin: 10px;
}

header nav a {
    color: #bc9d42;
    text-decoration: none;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 2px;
    // font-family: 'Source Sans Pro', sans-serif;
    transition: all 0.3s ease-out;
    cursor: pointer;
}

header nav a.hover {
    position: relative;
    margin: 0;
    padding: 0;
    transition: all 0.2s ease-in-out;
}

header nav a.hover::before,
header nav a.hover::after {
    content: "";
    position: absolute;
    bottom: -10px;
    width: 0px;
    height: 2px;
    margin: 5px 0 0;
    transition: all 0.2s ease-in-out;
    opacity: 0;
    background: #bc9d42;
}

header nav a.hover::before {
    left: calc(50%);
}

header nav a.hover::after {
    right: calc(50%);
}

header nav a.hover:hover::before,
header nav a.hover:hover::after,
header nav a.active::before,
header nav a.active::after {
    width: 50%;
    opacity: 1;
}

header nav ul.menu {
    width: 1170px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    list-style: none;
    padding: 0;
    margin: 0;
}

header .together-container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
}

header .together-container_mobil {
    display: none;
    position: absolute;
    top: 102px;
    left: 0;
    z-index: 100;
    transition: all 0.3s ease-out;
}

header .together-container_mobil .menu {
    display: flex;
    flex-direction: column;
    background: white;
}

header .together-container_mobil .menu li {
    margin-left: 0px;
    width: 100%;
    text-align: center;
}

header .together-container_mobil .menu li a {
    margin-left: 0px;
    width: 100%;
    border-bottom: 1px solid #c5910033;
    background-color: #252624;
    display: block;
    padding: 10px;
}

header .mobil_Header {
    display: none;
}

header .MMenu {
    position: absolute;
    top: 16px;
    right: 20px;
    width: 30px;
}

@media screen and (max-width:600px) {
    nav img {
        margin: 15px;
        width: 60px;
    }
    nav {
        padding-bottom: 0px;
    }

    header .together-container_mobil {
        top: 66px;
    }

    header .MMenu {
        top: 7px;
    }
}

// MENÜSOR ANIMÁLÁS GÖRGETÉSRE

div.together-container img.Logo {
    height: auto;
    max-height: 200px;
    // transition: all 0.2s ease-in-out;
}

div.together-container img.Logo.animate {
    // transform: scale(0.5);
    animation: sizeDown .3s ease-in-out forwards;
    // height: 50px;
}

header nav.navbar.fixed {
    // transform: scaleY(0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    animation: collapse .3s ease-in-out forwards;
}

nav.navbar.fixed div.together-container_mobil {
    top: 66px;
}

@keyframes collapse {
    from {
        max-height: 400px;
    }
    to {
        max-height: 80px;
    }
}

@keyframes sizeDown {
    from {
        max-height: 400px;
    }
    to {
        max-height: 60px;
    }
}

.extra_margin_top {
    margin-top: 80px;
}

// ****************
// DROPDOWN MENÜ
// ****************

ul.menu li.dropdown_container {
    position: relative;
    transition: all 0.2 ease-in-out;
}

nav.navbar.fixed ul.menu li.dropdown_container ul.dropdown_list {
    top: 75px;
}

ul.menu li.dropdown_container ul.dropdown_list {
    position: absolute;
    display: none;
    top: 60px;
    left: 0px;
    // width: 960px;
    // height: 380px;
    list-style: none;
    padding: 20px 0 0;
    margin: 0;
    background: #252624;
    z-index: 100;
}

// ul.menu li.dropdown_container ul.dropdown_list.flex {
//     display: flex;
// }

nav ul.menu li.dropdown_container ul.dropdown_list::before {
    content: "";
    display: block;
    position: absolute;
    top: -25px;
    left: 15px;
    // transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    border-bottom: 25px solid #252624;
}

ul.menu li.dropdown_container ul.dropdown_list li a {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 8px 10px;
    transition: all 0.2 ease-in-out;
    width: 250px;
    height: 60px;
    color: #bc9d42;
}

ul.menu li.dropdown_container ul.dropdown_list li a span:last-child {
    font-size: 12px;
    letter-spacing: initial;
}

ul.menu li.dropdown_container ul.dropdown_list li a:hover,
ul.menu li.dropdown_container ul.dropdown_list li a.active_dropdown {
    // background: #c28b07;
    // border: 1px solid #c28b07;
    box-shadow: 0 0px 8px rgba(194, 139, 7, 1);
    // color: white;
}

div.together-container_mobil ul.dropdown_list.mobile {
    margin: 0;
    padding: 0;
    display: block;
    list-style: none;
}

div.together-container_mobil ul.dropdown_list.mobile a {
    font-size: 10px;
    text-decoration: underline;
}

@media screen and (max-width:960px) {
    .together-container_mobil {
        width: 100%;
    }
    header .mobil_Header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        flex-direction: column;
    }
    .together-container {
        display: none !important;
    }
}