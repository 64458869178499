section.signup_hero {
    height: 630px;
    background: url('../images/signup/hero_bg2.jpg');
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    display: flex;
    justify-content: center;
    position: relative;
}

section.signup_hero div.leftside {
    position: absolute;
    text-align: center;
    width: 250px;
    margin: 300px 220px !important;
    right: 50%;
    background-image: -webkit-gradient(linear, left top, right top, from(#81652c), color-stop(#edcd79), to(#81652c));
    background-image: -webkit-linear-gradient(left, #81652c, #edcd79, #81652c);
    background-image: linear-gradient(90deg, #81652c, #edcd79, #81652c);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    font-weight: 400;
    font-size: 21px;
}

section.signup_hero div.rightside {
    position: absolute;
    text-align: center;
    width: 250px;
    margin: 300px 220px !important;
    left: 50%;
    background-image: -webkit-gradient(linear, left top, right top, from(#81652c), color-stop(#edcd79), to(#81652c));
    background-image: -webkit-linear-gradient(left, #81652c, #edcd79, #81652c);
    background-image: linear-gradient(90deg, #81652c, #edcd79, #81652c);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    font-weight: 400;
    font-size: 21px;
}

section.signup_hero div.leftside span,
section.signup_hero div.rightside span {
    font-family: 'Playfair Display', serif;
    display: block;
    font-style: italic;
}

_:-ms-lang(x), section.signup_hero div.leftside span,
_:-ms-lang(x), section.signup_hero div.rightside span {
    font-family: serif;
}

section.signup_content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 1120px;
    margin: 0 auto 60px;
}

img.side_packshot {
    position: absolute;
    top: 870px;
    left: 0;
    z-index: -1;
    user-select: none;
    width: 45vw;
}

section.signup_content h2.sub_hl {
    font-family: 'Playfair Display', serif;
    font-size: 31px;
    letter-spacing: 7px;
    text-align: center;
}

section.signup_content div.text {
    width: 810px;
}

section.signup_content div.text p {
    text-align: center;
}

section.signup_content p.italic {
    font-style: italic;
    font-size: 14px;
    margin: 30px 0;
}

section.signup_content div.form_container {
    width: 420px;
}

section.signup_content div.form_container form {
    width: 100%;
}

section.signup_content div.form_container div.form_group {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
}

div.form_container div.checkboxes div.form_group {
    flex-direction: row;
}

div.form_container div.checkboxes p {
    margin: 0;
    color: white;
    text-align: left;
    font-weight: 300;
    font-size: 12px;
}

div.form_container div.checkboxes p a {
    text-decoration: underline;
    font-weight: 500;
}

section.signup_content div.form_container div.form_group div {
    display: flex;
    justify-content: space-between;
}

div.form_container div.form_group div.phone_box {
    justify-content: flex-start !important;
    align-items: center;
}

div.form_container div.form_group div.phone_box input.form_control {
    width: calc(80%);
}

div.form_container div.form_group div.phone_box span {
    width: calc(20%);
    display: inline-flex;
    justify-content: center;
    color: white;
    font-weight: 300;
}

section.signup_content div.form_container div.form_group div + input.form_control {
    margin-top: 10px;
}

div.form_container div.form_group label {
    font-size: 18px;
    text-transform: uppercase;
    color: #bc9d42;
    margin-bottom: 10px;
}

div.form_container input.form_control,
div.form_container input[type=email] {
    border: 1px solid #7a662f;
    font-size: 16px;
    padding: 13px 8px;
    font-family: 'Montserrat', sans-serif;
    color: #bc9d42;
    outline: none;
}

div.form_container input[type=checkbox] {
    visibility: hidden;
    position: absolute;
    left: -20px;
}

div.form_container input[type=checkbox] + label {
    clear: none;
    position: relative;
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: transparent;
    border: 2px solid #be9d3a;
    margin-right: 15px;
    cursor: pointer;
}

div.form_container input[type=checkbox]:checked + label::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-40%, -60%);
    background: #be9d3a;
    width: 5px;
    height: 5px;
    border-radius: 50%;
}

div.form_container input[type=checkbox] + label::after {
    content: "Mező kitöltése kötelező!";
    position: absolute;
    top: -7px;
    left: -200px;
    font-size: 12px;
    color: red;
    border: 1px solid red;
    border-radius: 4px;
    padding: 5px;
    display: none;
}

div.form_container input[type=checkbox].error + label::after {
    display: block;
}

div.form_container input[type=submit] {
    cursor: pointer;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
}

span.error_message {
    color: red;
    padding: 10px 0;
    font-size: 12px;
    display: none;
}

input.error {
    border: 2px solid red !important;
}

input.valid {
    border: 2px solid green !important;
}

div.error_backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 200;
    display: none;
}

div.error_backdrop div.error_popup {
    background: white;
    padding: 30px 50px;
    border-radius: 10px;
    position: relative;
    // width: 400px;
}

div.error_backdrop div.error_popup span.email_space {
    color: red;
    font-style: italic;
}

div.error_backdrop div.error_popup div.close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    // background: red;
    cursor: pointer;
}

div.error_backdrop div.error_popup div.close span {
    position: absolute;
    top: 50%;
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 1px;
    background: black;
    transform: translateY(-50%);
}

div.error_backdrop div.error_popup div.close span:first-child {
    transform: rotate(45deg);
}

div.error_backdrop div.error_popup div.close span:last-child {
    transform: rotate(-45deg);
}

// ********************
// REGISZTRÁCIÓ LEZÁRVA
// ********************

section.signup_hero.reg_closed {
    height: 850px;
    background: url('../images/signup/reg_closed_bg.jpg');
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

section.signup_hero.reg_closed div.content {
    max-width: 670px;
    text-align: center;
    padding: 0 15px;
}

section.signup_hero.reg_closed div.content h1 {
    text-align: center;
    margin: 0 auto 40px;
    font-size: 44px;
}

section.signup_hero.reg_closed div.content p {
    color: white;
    font-family: 'Playfair Display', serif;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
}

// **************
// MEDIA QUERY
// **************

@media screen and (max-width: 1230px) {
    img.side_packshot {
        display: none;
    }

    section.signup_hero {
        height: 550px;
    }

    section.signup_content {
        width: 100%;
        padding: 0 15px;
    }
}

@media screen and (max-width: 820px) {
    section.signup_hero {
        /*height: 440px;*/
        height: auto;
        display: block;
        background-size: 180%;
        padding-top: 15px;
        background-position: top -80px center;
    }
    
    section.signup_hero h1.main_heading {
        font-size: 26px !important;
        margin: 0 auto 210px !important;
        padding: 0 20px;
        text-align: center;
        line-height: 35px !important;
        display: block !important;
        width: 100% !important;
    }
    
    section.signup_hero .leftside,
    section.signup_hero .rightside {
        position: relative !important;
        display: block !important;
        width: 100% !important;
        text-align: center;
        margin: 0  !important;
        margin-bottom: 20px !important;
        left: auto  !important;
        right: auto  !important;
        background-image: -webkit-gradient(linear, left top, right top, from(#81652c), color-stop(#edcd79), to(#81652c));
        background-image: -webkit-linear-gradient(left, #81652c, #edcd79, #81652c);
        background-image: linear-gradient(90deg, #81652c, #edcd79, #81652c);
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
        font-weight: 400;
        font-size: 21px; 
    }
    section.signup_content div.text {
        width: 100%;
    }

    section.signup_content h2.sub_hl {
        font-size: 26px;
    }

    section.signup_content div.text p {
        font-size: 14px;
    }

    div.form_container div.form_group label {
        font-size: 16px;
    }

    div.form_container input[type=checkbox] + label::after {
        content: "";
        padding: 10px;
        top: -5px;
        left: -5px;
    }
}

@media screen and (max-width: 470px) {
    section.signup_hero {
        /*height: 440px;*/
        height: auto;
        display: block;
        background-size: 250%;
        padding-top: 15px;
        background-position: top center;
    }
    
    section.signup_hero h1.main_heading {
        font-size: 26px !important;
        margin: 0 auto 190px !important;
        padding: 0 20px;
        text-align: center;
        line-height: 35px !important;
        display: block !important;
        width: 100% !important;
    }
    
    section.signup_hero .leftside,
    section.signup_hero .rightside {
        position: relative !important;
        display: block !important;
        width: 100% !important;
        text-align: center;
        margin: 0  !important;
        margin-bottom: 20px !important;
        left: auto  !important;
        right: auto  !important;
        background-image: -webkit-gradient(linear, left top, right top, from(#81652c), color-stop(#edcd79), to(#81652c));
        background-image: -webkit-linear-gradient(left, #81652c, #edcd79, #81652c);
        background-image: linear-gradient(90deg, #81652c, #edcd79, #81652c);
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
        font-weight: 400;
        font-size: 21px; 
    }

    section.signup_content div.form_container {
        width: 100%;
    }

    section.signup_content div.form_container div.form_group div {
        flex-flow: row wrap;
    }

    div.form_container input.form_control,
    div.form_container input[type=email] {
        margin-bottom: 10px;
        width: 100%;
    }

    section.signup_content h2.sub_hl {
        font-size: 18px;
    }

    div.form_container div.form_group label {
        font-size: 14px;
    }

    div.form_container input[type=checkbox]:checked + label::before {
        transform: translate(-60%, -40%);
    }

    section.signup_hero.reg_closed {
        height: 470px;
    }

    section.signup_hero.reg_closed div.content p {
        font-size: 14px;
        line-height: 24px;
    }
}