section.kavekapszula_hero {
    height: 730px;
    background: url('../images/kavekapszula/hero_bg.jpg');
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    display: flex;
    justify-content: center;
}

section.kavekapszula_hero .main_heading {
    text-align: center;
}

section.product_showcase {
    height: 1250px;
    background: url('../images/kavekapszula/kapszula_bg.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -80px;
}

section.product_showcase_title {
    background: black;
    height: 160px;
    padding: 40px 0px 40px 0px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

section.product_showcase_title h3 {
    font-family: 'Playfair Display', serif;
    font-weight: 300;
    letter-spacing: 2px;
    margin: 0;
    background-image: -webkit-gradient(linear, left top, right top, from(#81652c), color-stop(#edcd79), to(#81652c));
    background-image: -webkit-linear-gradient(left, #81652c, #edcd79, #81652c);
    background-image: linear-gradient(90deg, #81652c, #edcd79, #81652c);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    font-size: 27px;
    font-style: italic;
    width: auto;
    text-align: left;
}

_:-ms-lang(x), section.product_showcase_title h3 {
    font-family: serif;
}

section.product_showcase_title h3.left_side span:after {
    width: 45%;
    height: 1px;
    background: linear-gradient(90deg,#81652c 20%, #edcd79 30%,#81652c 50%,#edcd79 70%,#81652c 80%);
    content: '';
    display: inline-block;
    vertical-align: top;
    margin-left: 660px;
    margin-top: -34px;
}

section.product_showcase_title h3 span {
    display: block;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    font-style: normal;
}

section.product_showcase.newcaps {
    height: 1180px;
    background: url('../images/section2_bckg.jpg');
    background-position: bottom center;
    background-size: cover;
    margin-bottom: -80px;
    margin-top: 80px;
    //top: 0px;
}

section.product_showcase img.capsule_mobile {
    display: none;
}

div.showcase_wrapper {
    width: 1200px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}

section.product_showcase.newcaps div.showcase_wrapper.desktop {
    //margin-top: 80px;
}

div.showcase_cont_mobile {
    display: none;
}

.desktop {
    display: block;
}

div.showcase_wrapper_mobile div.showcase_box,
div.showcase_wrapper div.showcase_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    user-select: none;
    // max-width: 500px;
}

div.showcase_wrapper div.showcase_box {
    position: absolute;
}

div.showcase_wrapper_mobile div.showcase_box {
    position: initial;
}

div.showcase_wrapper div.showcase_box:first-child {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: 310px;
}

div.showcase_wrapper div.showcase_box:nth-child(2) {
    top: 700px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 310px;
}

div.showcase_wrapper div.showcase_box.desktop:nth-child(2) h4 {
    width: 750px;
    max-width: 750px;
}

div.showcase_wrapper div.showcase_box:nth-child(3) {
    top: 320px;
    left: 0;
    // transform: translateY(-50%);
    max-width: 310px;
}

div.showcase_wrapper div.showcase_box:last-child {
    top: 335px;
    right: 0;
    // transform: translateY(-50%);
    max-width: 310px;
}

div.showcase_wrapper div.showcase_box img:first-child {
    margin-bottom: 10px;
}

div.showcase_wrapper_mobile div.showcase_box h4,
div.showcase_wrapper_mobile div.showcase_box p,
div.showcase_wrapper div.showcase_box h4,
div.showcase_wrapper div.showcase_box p {
    font-family: 'Playfair Display', serif;
    font-weight: 300;
    text-align: center;
}

div.showcase_wrapper_mobile div.showcase_box h4,
div.showcase_wrapper div.showcase_box h4 {
    font-size: 26px;
    letter-spacing: 2px;
    color: #e9c977;
    margin: 10px 0;
    max-width: 310px;
}

div.showcase_wrapper_mobile div.showcase_box p,
div.showcase_wrapper div.showcase_box p {
    color: white;
    font-size: 14px;
    line-height: 26px;
    margin: 0;
    transition: all 0.2s ease-out;
    max-width: 310px;
}

_:-ms-lang(x), div.showcase_wrapper_mobile div.showcase_box p,
_:-ms-lang(x), div.showcase_wrapper div.showcase_box p {
    line-height: 18px;
}

div.showcase_wrapper div.showcase_box p {
    display: none;
    opacity: 0;
}

div.showcase_wrapper_mobile div.showcase_box p {
    display: block;
    padding: 0 100px;
}

div.showcase_wrapper div.showcase_box p.opened_text {
    animation: showText 0.2s forwards ease-out;
}

@keyframes showText {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

div.showcase_wrapper div.showcase_box .open_icon {
    margin-top: 20px;
    cursor: pointer;
}

div.showcase_wrapper div.showcase_box .open_icon:hover {
    animation: hoverTurn 0.2s forwards ease-out;
}

@keyframes hoverTurn {
    0% {
        transform: rotateZ(0);
    }
    50% {
        transform: rotateZ(130deg);
    }
    100% {
        transform: rotateZ(90deg);
    }
}

div.showcase_wrapper div.showcase_box .open_icon.opened {
    animation: onClickTurn 0.2s forwards ease-out;
}

@keyframes onClickTurn {
    0% {
        transform: rotateZ(0);
        // margin-top: 20px;
    }
    50% {
        transform: rotateZ(160deg);
    }
    100% {
        transform: rotateZ(135deg);
        // margin-top: 130px;
    }
}

div.legal_stripe p {
    color: #5f5f5f;
}

// *****************
// MEDIA QUERY
// *****************

@media screen and (max-width: 1450px) {
    section.kavekapszula_hero {
        height: 90vh;
        background: url('../images/kavekapszula/hero_bg_sm.jpg');
        background-repeat: no-repeat;
        // background-size: cover;
        background-position: top;
    }
}

@media screen and (max-width: 1210px) {
    section.kavekapszula_hero {
        // height: 90vh;
        background-size: contain;
    }

    section.product_showcase {
        height: auto;
        background: none;
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 20px;
    }
    
    section.product_showcase.newcaps {
        height: auto;
    }

    section.product_showcase div.capsule_container {
        background: url('../images/kavekapszula/kapszula_bg.jpg');
        background-repeat: no-repeat;
        background-position: top;
        background-size: contain;
        width: 100%;
    }
    
    section.product_showcase.newcaps div.capsule_container {
        background: transparent;
        margin-bottom: -80px;
    }
    
    section.product_showcase.newcaps div.showcase_wrapper_mobile div.showcase_box:nth-child(2) h4 {
        width: 75%;
        max-width: 75%;
    }
    
    section.product_showcase.newcaps div.showcase_wrapper_mobile div.showcase_box:nth-child(2) h4+div {
        width: 75%;
        max-width: 75%;
    }
    
    section.product_showcase.newcaps div.showcase_wrapper_mobile div.showcase_box:nth-child(2) div:not(.icon) img {
        width: 100%;
        max-width: 100%;
        height: 3px;
    }
    
    section.product_showcase.newcaps div.showcase_wrapper_mobile div.showcase_box:nth-child(2) p {
        width: 75%;
        max-width: 75%;
    }
    
    section.product_showcase.newcaps .showcase_wrapper_mobile {
        margin: 40px auto;
    }

    section.product_showcase img.capsule_desktop {
        display: none;
    }

    section.product_showcase img.capsule_mobile {
        display: block;
        width: 37%;
        margin: 0 auto;
    }

    .desktop {
        display: none;
    }

    // div.showcase_wrapper {
    //     display: none;
    // }

    div.showcase_cont_mobile {
        display: block;
        width: 100%;
        height: auto;
    }

    div.showcase_wrapper_mobile div.showcase_box p {
        padding: 0;
    }

    div.showcase_wrapper_mobile {
        align-items: center;
    }
}

@media screen and (max-width: 840px) {
    section.kavekapszula_hero {
        background: url('../images/kavekapszula/hero_bg_mobile.jpg');
        background-repeat: no-repeat;
        background-position: top;
    }

    div.showcase_wrapper_mobile div.showcase_box h4 {
        font-size: 22px;
        padding: 0 40px;
    }

    div.showcase_wrapper_mobile div.showcase_box p {
        font-size: 12px;
        line-height: 23px;
    }
}

@media screen and (max-width: 480px) {
    div.showcase_wrapper_mobile div.showcase_box div.icon img {
        width: 40px;
    }

    div.showcase_wrapper_mobile div.showcase_box h4 {
        font-size: 18px;
        padding: 0 30px;
    }

    div.showcase_wrapper_mobile div.showcase_box p {
        padding: 0 30px;
    }

    section.product_showcase img.capsule_mobile {
        width: 50%;
    }
}