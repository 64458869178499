div.cup_sizes_container.padding {
    padding: 20px 0;
}

section.products_content.recipes {
    width: 970px;
}

section.products_content.recipes .product_container {
    margin: 40px 0;
}

section.products_content.recipes .product_container div.cup_sizes_container {
    justify-content: center;
}

section.products_content.recipes .product_container .product_description h3 {
    text-align: left;
}

@media screen and (max-width: 980px) {
    section.products_content.recipes {
        width: 100%;
        padding: 0 15px;
    }
}

@media screen and (max-width: 470px) {
    section.products_content.recipes .product_container .product_packshot img {
        width: 100%;
    }
}