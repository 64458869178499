footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #252624; // margin-top: 30px;
  text-align: center;
  padding: 40px 0;
  // position: fixed;
  // bottom: 0;
  width: 100%;
}

footer a,
footer span {
  text-decoration: none;
  color: #bc9d42;
  font-size: 12px;
}

footer a {
  margin: 10px;
}

footer img.footer_logo {
  width: 100%;
}

div.links {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
}

footer div.social {
  margin: 20px 0;
}

footer div.social img {
  transition: all 0.2s ease-out;
}

footer div.social img:hover {
  transform: scale(1.05);
}

@media screen and (max-width: 600px) {
  div.links {
    flex-direction: column;
  }
}